import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DefaultFilter } from '../../components/StaticEapTable';
import { PaginationEapTable } from '../../components/PaginationEapTable';
import * as EapDisplayDate from '../../components/EapDisplayDate';
import EnterMSD from '../../components/EnterMSDModal';
import AdminIcon from '../../components/AdminIcon';
import GenericProductLink from '../../components/GenericProductLink';
import ReleaseResultsModal from '../../components/ReleaseResultsModal';
import * as ProductStatus from '../../components/ProductStatusFilter';

const GroupResultsTable = ({
  results,
  totalResults,
  loadResults,
  releaseResults,
  groupId,
  permissions,
}) => {
  const [showModal, setShowModal] = useState({
    type: '',
    data: {},
  });

  const columns = [
    {
      Header: 'Username',
      accessor: 'username',
      Filter: DefaultFilter,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      Filter: DefaultFilter,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      Filter: DefaultFilter,
    },

    {
      Header: 'Product',
      accessor: 'productName',
      Filter: DefaultFilter,
    },
    {
      Header: 'Status',
      maxWidth: 150,
      accessor: 'status',
      Cell: ({ row: { original: { status, assignedProductId } } }) => (
        <GenericProductLink
          text={status}
          assignedProductId={assignedProductId}
          baseUrl="/api/user/assigned-product/"
          isAdmin
        />
      ),
      Filter: ProductStatus.Filter,
      filterMethod: ProductStatus.Method
    },
    {
      Header: 'Updated',
      accessor: 'assignedProductUpdated',
      disableFilters: true,
      Cell: ({ row: { original: { assignedProductUpdated } } }) => (
        <EapDisplayDate.Display date={assignedProductUpdated} />
      ),
      sortMethod: EapDisplayDate.sort
    },
    {
      Header: 'Actions',
      disableFilter: true,
      disableSort: true,
      Cell: ({ row: { original: record } }) => (
        <div>
          <AdminIcon
            onClick={() => setShowModal({ type: 'msd', data: record })}
            icon="fa-plus"
            toolTip="Add MSD"
            permission="caps"
            permissions={record.configs}
          />
          <AdminIcon
            onClick={() => setShowModal({ type: 'msd', data: record })}
            icon="fa-plus"
            toolTip="Add MSD"
            permission="capsp"
            permissions={record.configs}
          />
          <AdminIcon
            onClick={() => setShowModal({ type: 'msd', data: record })}
            icon="fa-plus"
            toolTip="Add MSD"
            permission="spac"
            permissions={record.configs}
          />

          {
            record.configs && record.configs.suppressed
              ? (
                <AdminIcon
                  onClick={() => setShowModal({ type: 'release', data: record })}
                  icon="fa-share-square-o"
                  toolTip="Release Results"
                />
              ) : ''
          }
        </div>
      ),
    }
  ];
  return (
    <>
      <PaginationEapTable
        data={results}
        columns={columns}
        total={totalResults}
        fetchData={(params) => loadResults(groupId, params)}
      />
      <EnterMSD
        showModal={showModal.type === 'msd'}
        closeModal={() => setShowModal({ type: '', data: {} })}
        testId={showModal.data.assignedProductId}
        groupId={groupId}
        adminGroupId={permissions.id}
        firstName={showModal.data.firstName}
        lastName={showModal.data.lastName}
        gender={showModal.data.gender}
        grade={showModal.data.grade}
      />
      <ReleaseResultsModal
        showModal={showModal.type === 'release'}
        productName={showModal.data.productName}
        firstName={showModal.data.firstName}
        lastName={showModal.data.lastName}
        permissions={showModal.data.configs}
        releaseResults={() => {
          const {
            data: {
              assignedProductId,
              firstName,
              lastName,
            },
          } = showModal;
          releaseResults({
            adminGroupId: permissions.id,
            groupId,
            assignedProductId,
            firstName,
            lastName,
          });
          setShowModal({ type: '', data: {} });
        }}
        closeModal={() => setShowModal({ type: '', data: {} })}
      />
    </>
  );
};

GroupResultsTable.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    productName: PropTypes.string,
    status: PropTypes.string
  })).isRequired,
  permissions: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  releaseResults: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired
};

export default GroupResultsTable;
